<template>
    <div class="screen2" :style="'background:url(' + bg + ') no-repeat'"> 
        <div class="d-flex">
            <div class="form_box">
                <div class="blue_title">
                    Para finalizar favor de completar <br>
                    las siguientes preguntas
                </div>
                <div class="black_box">
                      <form action="">
                          <div>
                                <div class="form-group">
                                    <label for="">¿Cuál es tu estado civil?</label>
                                    <select name="" id="" v-model="marital" @change="onChangeMarital($event)">
                                        <option value="0" selected="selected">Seleccione uno...</option>
                                        <option value="1">soltero(a)</option>
                                        <option value="2">casado(a)</option>
                                        <option value="3">unión libre</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="">¿Qué destino vacacional prefieres?</label>
                                    <select name="" id="" v-model="vacational" @change="onChangeVacational($event)">
                                        <option value="0" selected="selected">Seleccione uno...</option>
                                        <option value="1">Playa</option>
                                        <option value="2">Ciudad</option>
                                        <option value="3">Montaña</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="">¿Con cuanta frecuencia vacacionas al año?</label>
                                    <select name="" id="" v-model="frequency" @change="onchangeFrequency($event)">
                                        <option value="0" selected="selected">Seleccione uno...</option>
                                        <option value="1">1 al año</option>
                                        <option value="2">2 al año</option>
                                        <option value="3">3 al año</option>
                                    </select>
                                </div>
                          </div>
                        <router-link :disabled="!benabled" :to="'screen3?city=' + queryText"  tag="button" class="btn_capsul">COMPLETAR REGISTRO</router-link>
                    </form>
                </div>
            </div>
            <img src="../assets/img/logo.png" class="logo" alt="">
            <div class="form_privacy">
              <button @click="policy()" class="button_privacy"><img  src="../assets/img/banner.png" class="privacy" alt=""></button>
            </div>
        </div>
    </div>
</template>


<style  scoped>
.screen2{
    background-size: cover !important;
    background-position: center !important;
    height: 120%;    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.black_box {
    text-align: center;
    padding: 10px 0;
}
.form-group:not(:last-child){
    border-bottom: 2px solid #fff;
}
label{
    margin-top: 20px;
}
.btn_capsul {
    width: 250px;
}
.logo {
    margin: 70px auto 0;
}
@media(max-width:767px){
    .screen2 {
        height: auto;
    }
}
@media(max-width:400px){
    .screen2 {
        height: auto;
    }
}
@media(max-width:380px){
    .screen2 {
        height: auto;
        padding: 20px 0;
    }
    .logo{
        margin: 10px auto 0;
    }
}
</style>


<script>

import tijuana_img from '../assets/img/FONDOPANTTJ02.png';
import cdmx_img from '../assets/img/FONDOPANTCDMX02.png';
import gdl_img from '../assets/img/FONDOPANTGDL02.png';
export default {
    name:"Screen2",
    data(){
        return{
        cities:[
            {
                name:"tijuana",
                bg_img:tijuana_img
            },
            {
                name:"cdmx",
                bg_img:cdmx_img
            },
            {
                name:"gdl",
                bg_img:gdl_img
            }
        ],
        bg:"",
        queryText:"",
        marital:"",
        vacational:"",
        frequency:"",
        benabled: false
        }    
    },
    mounted() {
        var  parameters = this.$route.query.city
        this.cities.map(city => {
            if(city.name == parameters){
                this.bg = city.bg_img;
                 this.queryText = city.name
            }
        })
    },
    methods:{
        onChangeMarital(event) {
            localStorage.marital = event.target.options[event.target.options.selectedIndex].text;
            this.checkFields();
        },
        onChangeVacational(event) {
            localStorage.vacational = event.target.options[event.target.options.selectedIndex].text;
            this.checkFields();
        },
        onchangeFrequency(event) {
            localStorage.frequency = event.target.options[event.target.options.selectedIndex].text;
            this.checkFields();
        },
        checkFields() {
            this.benabled = false;
            if (this.marital > 0 && this.vacational > 0 && this.frequency > 0) {
                this.benabled = true;
            }
        },
        policy() {
            var city = localStorage.getItem('city');
            switch (city) {
                case 'tijuana':
                    window.location.href='http://www.conocenos.travelzone.com.mx/premio-comida/';
                    break;
                case 'cdmx':
                    window.location.href='http://www.conocenos.travelzone.com.mx/8875-2/';
                    break;
                case 'gdl':
                    window.location.href='http://www.conocenos.travelzone.com.mx/premio-hospedaje-aria-ocean/';
                    break;
                case '':
                    this.$router.push('/?city='+this.queryText)
                    break;
            }
        }
    }
}
</script>